import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { secondToObject } from '@util/time';

interface IUseTimerProps {
  defaultEndDate: Date;
  onTimeOver?: () => void;
  options?: {
    includeDate?: boolean;
  };
}

const diffDateTime = (endDate: Date) => {
  const endDateTime = endDate.getTime();
  const currentDateTime = new Date().getTime();
  const diffTime = endDateTime - currentDateTime;
  return diffTime < 0 ? 0 : diffTime;
};

const numberLeadingZero = (time: number) => {
  return time.toFixed(0).padStart(2, '0');
};

export default function useTimer({ defaultEndDate, onTimeOver, options }: IUseTimerProps) {
  const includeDate = options?.includeDate ?? true;
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [diff, setDiff] = useState<number>(() => diffDateTime(endDate));

  const diffTime = secondToObject(diff / 1000);

  /**
   * %d: for day
   * %h: for hours
   * %m: for minutes
   * %s for seconds
   */
  const format = (textArg: string) => {
    let text = textArg;
    if (!includeDate || diffTime.day <= 0) {
      text = text.replace('%' + text.split('%').find(s => s.includes('d')), '').replace('  ', ' ');
    }
    return text.replace(/%d/g, String(diffTime.day)).replace(/%h/g, numberLeadingZero(includeDate ? diffTime.hour : diffTime.fullhour)).replace(/%m/g, numberLeadingZero(diffTime.minute)).replace(/%s/g, numberLeadingZero(diffTime.second));
  };

  useEffect(() => {
    if (!endDate) return;
    let timer;
    const diffTimeFunc = () => {
      const diffTime = diffDateTime(endDate);

      setDiff(diffTime);
      if (diffTime === 0) {
        onTimeOver && onTimeOver();
        if (timer) clearInterval(timer);
      }
    };

    diffTimeFunc();
    timer = setInterval(diffTimeFunc, 1000);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [endDate]);

  return {
    ...diffTime,
    format: format,
    setEndDate,
  };
}
